.has-slider{
	header{
		background-color: rgba(37, 37, 37, 0);
	}
}
header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 4;
	height: 100px;
	display: flex;
	background-color: rgba(37, 37, 37, 1);
	min-height: 50px;
	transition: all .3s;
	@media (max-width: $md) {
		height: 60px;
	}


	.main-menu{
		width: 50%;
		@media (max-width: $md) {
			//width: calc(100% - 40px - 5%);
			display: flex;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			height: 100vh;
			width: 100%;
			background-color: $white;
			justify-content: center;
			align-items: center;
			top: -100vh;
			transition: top .3s;
		}
		ul.menu{
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0;

			@media (max-width: $md) {
				display: block;
			}

			li{
				list-style: none;

				@media (max-width: $md) {
					line-height: 60px;
					height: 60px;
					text-align: center;

					&.menu-item-has-children{
						line-height: 60px;
						height: auto;
					}
				}

				a{
					color: $white;
					text-decoration: none;
					@include ms-respond(font-size, .6);
					display: inline-block;
					padding: 0 10px;
					text-transform: uppercase;
					transition: all .3s;
					letter-spacing: 2px;

					@media (max-width: $md) {
						color: $black;
						line-height: 60px;
						height: auto;
					}

					&:hover{
						color: $primary;
						transition: all .3s;
					}
				}
			}
		}
	}

	.menu-main-menu-container{
		@media (max-width: $md) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	ul{
		li{
			a{
				height: 100px;
				line-height: 100px;
				min-height: 50px;
				transition: all .3s;
			}
		}
	}

	.social-menu{
		width: 25%;
		padding-right: 50px;
		@media (max-width: $md) {
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			padding-right: 5%;
			display: none;
		}
		ul{
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0;
		}
		a{
			color: $white;
			display: inline-block;
			@include ms-respond(font-size, 1);
			//height: 50px;
			//line-height: 50px;
			transition: all .3s;

			&:hover{
				color: $primary;
				transition: all .3s;
			}
		}
	}

	.current_page_item,
	.current-menu-parent{
		&>a{
			position: relative;
			&:after{
				position: absolute;
				bottom: 25%;
				left: 0;
				right: 0;
				background-color: $primary;
				content: '';
				display: block;
				height: 2px;
			}
		}
	}
}

.menu-open{

	header{
		height: 100vh;
	}

	.main-menu{
		transition: top .3s;
		top: 0;
	}

	.social-menu{
		display: block;
	}

	.language-menu{
		display: block;
		margin: 0;

		&>div{
			&>ul{
				&>li{
					&>a{
						padding: 10px 20px;
					}
				}
			}
		}

		a{
			padding: 10px;
		}

		li{
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.sub-menu{
			max-height: unset;
			overflow: visible;
			background-color: $white!important;
			display: flex;
			min-width: unset;

			a{
				height: 100px;
				line-height: 100px;
				min-height: 50px;
			}
		}
	}

	body{
		overflow: hidden;
	}
}

.language-menu{
	position: absolute;
	right: 2%;
	color: $white;
	top: 50%;
	transform: translate(0, -50%);

	@media (max-width: $lg) {
		right: 0;
	}
	@media (max-width: $md) {
		position: absolute;
		bottom: 100px;
		left: 0;
		right: 0;
		padding: 0;
		display: none;
		width: 100%;
		color: $black;
		top: unset;
		text-align: center;
		a{
			color: $black;
		}
	}

	a{
		text-decoration: none;
		color: $white;

		@media (max-width: $md) {
			color: $black;
			@include ms-respond(font-size, .6);
		}

		&.active{
			text-decoration: underline;
		}
	}

	ul{
		margin: 0;
		padding: 0;
		justify-content: center;
		align-items: center;

		li{
			list-style: none;
			padding: 0 10px;
			text-align: center;
			height: 100px;
			line-height: 100px;
			a{
				height: 100px;
				line-height: 100px;
			}
		}
	}

	.sub-menu{
		max-height: 0;
		overflow: hidden;
		li {
			height: 50px;
			line-height: 50px;
			a {
				height: 50px;
				line-height: 50px;
			}
		}
	}

	&:hover{
		.sub-menu{
			max-height: unset;
		}
	}

	a{
		&:hover{
			//transform: scale(1.3);
			color: $primary;
		}
	}
}