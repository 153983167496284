#downloads{
	.accordion{
		.collapse{
			p{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				a{
					width: 50%;
					border: 1px solid $white;
					img{
						width: 100%;
						max-width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center center;
						font-family: 'object-fit: cover; object-position: center center;';
					}
				}
			}
		}
	}
}