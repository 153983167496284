.gallery {
	display: flex;
	flex-wrap: wrap;

}
.wysiwyg-gallery-item {
	margin: 0 !important;
}
.wysiwyg-gallery-item a {
	width: 100%;
	padding-top: 100%;
	display: inline-block;
	position: relative;
}
.wysiwyg-gallery-item img {
	position: absolute;
	top: 0;
	left: 0;
	//border: 0 !important;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	display: block;
	max-width: 100%;
}
.wysiwyg-gallery-item {
	// margin: 0 !important;
	position: relative;
}
.wysiwyg-gallery-icon{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.wysiwyg-gallery-image{
	height: 100%!important;
	width: 100%!important;
	object-fit: cover;
	font-family: 'object-fit: cover;';
}
.wysiwyg-gallery-lightbox {
	display: none;
	position: fixed;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: black;
	z-index: 3;
}
.wysiwyg-gallery-lightbox img {
	max-height: 50vh;
	width: auto !important;
	margin: auto;
}
.wysiwyg-gallery-lightbox-content {
	position: relative;
	margin: auto;
	padding: 0;
	width: 90%;
	max-width: 1200px;
	background-color: black;
}
.wysiwyg-gallery-close {
	color: white;
	position: absolute;
	top: 15vh;
	right: 5vw;
	font-size: 35px;
	font-weight: bold;
	transition: all .3s;

}
.wysiwyg-gallery-close:hover,
.wysiwyg-gallery-close:focus {
	color: $primary;
	transition: all .3s;
	text-decoration: none;
	cursor: pointer;
}
.wysiwyg-gallery-slide {
	display: none;
	text-align: center;
}
.wysiwyg-gallery-prev,
.wysiwyg-gallery-next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 16px;
	margin-top: -50px;
	color: white;
	font-weight: bold;
	font-size: 20px;
	transition: 0.6s ease;
	border-radius: 0 3px 3px 0;
	user-select: none;
	-webkit-user-select: none;
	text-decoration: none;
	transition: all .3s;
	cursor: pointer;
	font-family: inherit;

	&:hover{
		color: $primary;
		transition: all .3s;
	}
}
.wysiwyg-gallery-next {
	right: 0;
	border-radius: 3px 0 0 3px;
}
.wysiwyg-gallery-prev:hover,
.wysiwyg-gallery-next:hover {
	background-color: rgba(0, 0, 0, 0.8);
}
.wysiwyg-gallery-slide-numbertext {
	color: #f2f2f2;
	font-size: 12px;
	padding: 8px 12px;
	position: absolute;
	top: 0;
	display: none;
}
.wysiwyg-gallery-slide-caption-container {
	text-align: center;
	background-color: black;
	padding: 2px 16px;
	color: white;
}
.gallery img.wysiwyg-gallery-slide-preview {
	opacity: 0.6;
	max-width: 80px;
	height: auto;
}
.active,
.wysiwyg-gallery-slide-preview:hover {
	opacity: 1;
}
.gallery img.hover-shadow {
	transition: 0.3s;
}
.hover-shadow:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.row > .column {
	padding: 0 8px;
	display: none;
}
.row:after {
	content: "";
	display: table;
	clear: both;
}
.column {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	display: none;
}

html.wysiwyg-gallery-slide-open {
	overflow: hidden;

	.text{
		position: static;
	}
}

.gallery img{
	border: none!important;
	max-width: 100%;
}


.gallery-item{
	overflow: hidden;
	border: 1px solid $white !important;

	&:hover {

		img{
			transition: transform .3s;
			transform: scale(1.1);
		}
	}

	img {
		height: 100%;

		transition: transform .3s;
		cursor: pointer;


	}
}

.gallery-icon{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: $black;
}
.gallery-item{
	position: relative;
	margin: 0!important;
}

.gallery-columns-4{
	.gallery-item{
		@media (max-width: $md) {
			width: 50%!important;
		}
	}
}

.wysiwyg-gallery-slide-image{
	pointer-events: none;
}