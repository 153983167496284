.menu-toggler{
	height: 40px;
	width: 40px;
	position: relative;
	background-color: transparent;
	border: none;
	padding: 0;
	z-index: 3;
	cursor: pointer;
	display: none;

	@media (max-width: $md) {
		display: block;
	}

	&:hover{
		span{
			background-color: $primary;
			transition: all .3s;
		}
	}

	span{
		height: 2px;
		width: 40px;
		transition: all .3s;
		background-color: $white;
		display: inline-block;
		position: absolute;
		left: 0;

		&:first-of-type{
			top: 8px;
		}

		&:nth-of-type(2){
			top: 50%;
			transform: translate(0, -50%);
		}

		&:last-of-type{
			bottom: 8px;
		}
	}
}

.menu-toggler-wrapper{
	justify-content: center;
	align-items: center;
	margin-left: 5%;
	display: none;
	top: 10px;
	position: absolute;
	@media (max-width: $md) {
		display: flex;
	}
}

.menu-open{

	.menu-toggler-wrapper{

	}

	.menu-toggler{
		span{

			background-color: $black;

			&:first-of-type{
				top: 50%;
				transform: rotate(45deg) translate(0, -50%);
			}

			&:nth-of-type(2){
				opacity: 0;
			}

			&:last-of-type{
				bottom: 50%;
				transform: rotate(-45deg) translate(0, 50%);
			}
		}
	}

	.social-menu{
		a{
			color: $black;
		}
	}
	header{

	}
}