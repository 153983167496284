$modularscale: (
		base: 12px,
		ratio: $golden,
		400px: (
				base: 10px,
		),
		900px: (
				base: 13px,
		),
		1200px: (
				base: 10px,
		),
);

$xl: 1199px;
$lg: 991px;
$md: 767px;
$sm: 575px;

$primary: #12b2cb;
$white: #FFFFFF;
$grey: #252525;
$black: #141414;
$font-color: #808080;
$facebook: rgb(59, 89, 152);
$twitter: rgb(85, 172, 238);
$googleplus: rgb(203, 32, 39);
$email: rgb(125, 125, 125);

$regular: 300;
$regular-italic: 300i;
$bold: 700;
$bold-italic: 700i;
