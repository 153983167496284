.not-found{
	height: 100vh;
	position: relative;
	overflow: hidden;
	display: block;


	p{
		color: $white;
	}

	.not-found-image{
		position: relative;
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.3);
		}

		img{
			height: 100vh;
			width: 100%;
			display: block;
			object-fit: cover;
		}
	}

	.not-found-content{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: $white;

		img{
			max-width: 100%;
		}
	}

	a{
		height: 50px;
		border: 2px solid $white;
		width: auto;
		color: $primary;
		font-family: inherit;
		outline-color: $primary;
		display: inline-block;
		transition: all .3s;
		cursor: pointer;
		background-color: $white;
		min-width: 200px;
		line-height: 48px;
		&:hover{
			color: $white;
			border-color: $primary;
			transition: all .3s;
			background-color: $primary;
		}

	}

}