@import './node_modules/modularscale-sass/stylesheets/_modularscale';

html {
	font-size: 10px;
	font-family: 'Source Sans Pro', sans-serif;
}

body {
	font-weight: $regular;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
{
	font-weight: $regular;
	letter-spacing: -1px;
	text-transform: uppercase;
	margin-bottom: 0.4em;
}

h1,
h2{
	position: relative;
	font-weight: $bold;
	display: inline-block;
	&:before{
		display: block;
		content: '';
		width: 10%;
		min-width: 75px;
		height: 4px;
		top: -20px;
		background-color: $primary;
		position: absolute;
		left: 0;
		//left: 50%;
		//transform: translate(-50% ,0);
	}

	&[style="text-align: center;"]{
		display: block;
		width: 100%;
		&:before{
			left: 50%;
			transform: translate(-50% ,0);
		}
	}
}

h1,
.h1{
	@include ms-respond(font-size, 2.5);
}

h2,
.h2{
	@include ms-respond(font-size, 2.2);
}

h3,
.h3{
	@include ms-respond(font-size, 1.9);
}

h4,
.h4{
	@include ms-respond(font-size, 1.6);
}

h5,
.h5{
	@include ms-respond(font-size, 1.3);
}

h6,
.h6{
	@include ms-respond(font-size, 1);
}

h1{
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6{
		margin-top: 0;
	}
}

p {
	@include ms-respond(font-size, 1);
	color: $font-color;
	max-width: 800px;

	&[style="text-align: center;"] {
		margin: auto;
	}
}

td{
	@include ms-respond(font-size, 1);
	color: $font-color;
}

b,
strong{
	font-size: inherit;
	font-weight: $bold;
}

a {
	font-size: inherit;
	text-decoration: underline;
	color: $font-color;
	transition: all .3s;
	&:hover{
		color: $primary;
		transition: all .3s;
	}
}

i {
	font-size: inherit;
	font-weight: $bold-italic;
}

b i,
strong i,
i b,
i strong{
	font-weight: $bold-italic;
}

small {
	@include ms-respond(font-size, 0.7);
}

ul,
ol{
	@include ms-respond(font-size, 1);
	color: $font-color;
}