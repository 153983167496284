@import './node_modules/modularscale-sass/stylesheets/_modularscale';

.accordion{
  margin: 7em auto;
  .head{
    position: relative;
    justify-content: flex-start;
    align-content: center;
    cursor: pointer;
    min-height: 50px;
    line-height: 50px;
    background-color: transparent;
    display: block;
    width: 100%;
    @include ms-respond(font-size, 1);
    text-align: left;
    letter-spacing: 2px;
    border: none;
    font-weight: $regular;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0;
    outline: none;
    @media (max-width: $md) {
      @include ms-respond(font-size, 1);
    }
    .icon{
      width: 25px;
      height: 25px;
      right: 3px;
      top: 11px;
      position: absolute;
      span{
        width: 25px;
        height: 1px;
        background-color: $primary;
        display: inline-block;
        transition: all .3s;
        &:nth-of-type(1){
          //transform: translate(0, 50%);
          top: 50%;
          position: absolute;
        }
        &:nth-of-type(2){
          transform: rotate(90deg);
          position: absolute;
          top: 50%;
        }
      }
    }
  }
  .collapse{
    max-height: 0;
    overflow: hidden;
    transition: all .3s;
    visibility: hidden;

    h6{

      @media (max-width: $md) {
        @include ms-respond(font-size, 0.7);
      }
    }
  }
  .panel{
    border-bottom: 1px solid $primary;
    &:first-of-type{
      border-top: 1px solid $primary;
    }
    &.open{
      .icon{
        span{
          &:nth-of-type(2){
            transform: rotate(0deg);
            transition: all .3s;
          }
        }
      }
      .collapse{
        margin-bottom: 4vh;
        visibility: visible;
      }
    }
  }
}