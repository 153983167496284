.logo{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	width: calc( 25% - 20px);
	padding-right: 5%;

	@media (max-width: $md) {
		width: 100%;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		padding-right: 0;
	}

	img{
		display: block;
		width: 100%;
		max-width: 100%;
		max-height: 28px;
	}
}