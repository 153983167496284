.projects {
	padding: 8% 0;
	.project {

		.project-link{
			display: flex;
		}

		.project-image {
			width: 25%;
			order: 1;
			img {
				display: block;
				width: 100%;
				object-fit: cover;
				height: 100%;

			}
		}
		.project-content {
			margin-left: 30px;
			margin-right: 0;
			width: 75%;
			display: block;
			order: 2;
			p{
				margin-top: 0;
			}
		}

		&:nth-of-type(odd){
			.project-image {
				order: 2;
			}
			.project-content {
				order: 1;
				margin-left: 0px;
				margin-right: 30px;
			}
		}
	}

	li {
		list-style: none;
		margin-bottom: 30px;
		a {
			text-decoration: none;
		}
	}
}