.slider{
	height: 65vh;
	position: relative;
	overflow: hidden;
	opacity: 0;

	&.slick-initialized {
		opacity: 1;
	}

	.slick-track{

	}

	p{
		color: $white;
	}

	.slider-image{
		position: relative;
		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.3);
		}

		img{
		 	height: 65vh;
			width: 100%;
			display: block;
			object-fit: cover;
			object-position: center center;
			font-family: 'object-fit: cover; object-position: center center;';
		}
	}

	.slider-content{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: $white;
		max-width: 60%;
		margin: auto;

		img{
			max-width: 100%;
			margin: auto;
			display: block;
		}

		h1 {
			@include ms-respond(font-size, 3);
			margin-bottom: 10px;
			@media (max-width: $md) {
				@include ms-respond(font-size, 2.2);
			}
		}

		h2 {
			@include ms-respond(font-size, 2.8);
			margin-bottom: 10px;
			@media (max-width: $md) {
				@include ms-respond(font-size, 2.1);
			}
		}

		h3 {
			@include ms-respond(font-size, 2.7);
			margin-bottom: 10px;
			@media (max-width: $md) {
				@include ms-respond(font-size, 2);
			}
		}

		h4 {
			@include ms-respond(font-size, 2.5);
			margin-bottom: 10px;
			@media (max-width: $md) {
				@include ms-respond(font-size, 1.9);
			}
		}

		h5 {
			@include ms-respond(font-size, 2.1);
			margin-bottom: 10px;
			@media (max-width: $md) {
				@include ms-respond(font-size, 1.8);
			}
		}

		h6 {
			@include ms-respond(font-size, 1.9);
			margin-bottom: 10px;
			@media (max-width: $md) {
				@include ms-respond(font-size, 1.7);
			}
		}

		p {
			@include ms-respond(font-size, 1.3);
			@media (max-width: $md) {

			}
		}
	}

	.slider-link{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		padding-top: 50px;
	}

	.slick-slide{
		position: relative;
	}

	.slick-arrow{
		position: absolute;
		top: 50%;
		z-index: 2;
		transform: translate(0, -50%);
		background-color: transparent;
		border: none;
		width: 30px;
		height: 30px;
		outline: none;
		cursor: pointer;
		transition: all .3s;

		&:hover{
			border-color: $primary;
			transition: all .3s;
		}
	}

	.slick-prev{
		left: 30px;
		border-left: 2px solid $white;
		border-top: 2px solid $white;
		transform: rotate(-45deg);
	}
	.slick-next{
		right: 30px;
		border-right: 2px solid $white;
		border-top: 2px solid $white;
		transform: rotate(45deg);
	}

	.slick-dots{
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;

		li{
			list-style: none;
		}

		button{
			margin: 0 5px;
			border: 2px solid $white;
			border-radius: 50%;
			background-color: transparent;
			color: transparent;
			outline: none;
			cursor: pointer;
			width: 10px;
			height: 10px;
			padding: 0;
			transition: all .3s;

			&:hover{
				background-color: $primary;
				border-color: $primary;
			}
		}

		.slick-active{
			button{
				background-color: $white;
				transition: all .3s;

				&:hover{
					background-color: $primary;
					border-color: $primary;
				}
			}
		}
	}
}