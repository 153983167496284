.page-links{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;


	&>div{
		background-color: $black;
		width: 33.33333%;
		@media (max-width: $md) {
			width: 100%;
		}
	}

	.link-image{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.link-content{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@include ms-respond(font-size, 2.2);
		transition: all .3s;
		color: $black;
		background-color: rgba(0,0,0,0.3);
		text-transform: uppercase;
		font-weight: $bold;

		&>span{
			display: inline-block;
			max-width: 80%;
			margin: auto;
			text-align: center;
		}
	}

	a{
		padding-top: 100%;
		position: relative;
		width: 100%;
		overflow: hidden;
		display: inline-block;

		@media (max-width: $sm) {
			width: 100%;
			padding-top: 100%;

		}

		img{
			display: block;
			width: 100%;
			height: 100%;
			max-width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			object-fit: cover;
			transition: all .3s;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}

		.link-content{
			color: $white;
		}

		&:hover{
			.link-content{
				background-color: rgba(0,0,0,0.5);
				transition: all .3s;
				transform: scale(1.1);
			}
			img{
				transform: scale(1.1);
				transition: all .3s;
				filter: grayscale(100%);
			}
		}
	}
}