*, *::before, *::after {
	box-sizing: border-box;
}

.clearfix:after {
	content: '';
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
}

html {
	margin: 0;
	min-width: 320px;
	//height: 100vh;
}

body {
	margin: 0;
	//min-height: 100vh;
}

.has-slider{
	main{
		.content{
			margin-top: 8%;
			margin-bottom: 8%;
			@media (max-width: $md) {
				margin-top: 20%;
				margin-bottom: 20%;
			}
		}
	}
}

main {
	//min-height: 100vh;
	//padding: 5%;

	.content{
		max-width: 800px;
		margin: calc(8% + 100px) auto 8% auto;
		@media (max-width: $lg) {
			max-width: 100%;
			padding-left: 5%;
			padding-right: 5%;
		}
	}
	@media (max-width: $lg) {

	}
}

img{
	max-width: 100%;
	height: auto;
}

.content{
	a{
		&>img{
			background-color: #cdcdcd;
		}
	}
}