.sub-menu{
	padding: 0;
	background-color: rgba(37, 37, 37, 1);
	//padding-bottom: 15px;
	min-width: 100%;

	.current_page_item{
		&>a{
			&:after{
				left: 5%;
				right: 5%;
			}
		}
	}
}

header{
	.main-menu{
		ul.menu{
			li{
				position: relative;
				&:hover{
					ul.sub-menu{
						position: absolute;
						max-height: unset;
						width: auto;
						transition: all .3s;
					}
				}
			}
			ul.sub-menu{
				display: block;
				max-height: 0;
				overflow: hidden;
				width: 0;
				transition: all .3s;
				li{
					line-height: 50px;
					height: 50px;
					a{
						@include ms-respond(font-size, .2);
						line-height: 50px;
						height: 50px;
						white-space: nowrap;
						min-height: 0px;
					}
				}
				@media (max-width: $md) {
					display: none;
				}

				&.open{
					@media (max-width: $md) {
						max-height: unset;
						display: block;
					}
				}
			}
		}
	}
}

.menu-open{
	header{
		.main-menu{
			ul.menu{
				li{
					position: static;
					ul.sub-menu{
						position: static;
						width: auto;
					}
				}
				ul.sub-menu{
					background-color: transparent!important;
					@media (max-width: $md) {
						li{
							line-height: 30px;
							height: 30px;
							a{
								@include ms-respond(font-size, .2);
								line-height: 30px;
								height: 30px;
							}
						}
					}
				}
			}
		}
	}
}