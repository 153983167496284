.shares{
	margin: 10em auto;
	ul{
		display: flex;
		margin: 0;
		padding: 0;
	}
	li{
		list-style: none;
		padding: 10px;
	}

	a{
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		border-radius: 50%;
		transition: all .3s;

		&:hover{
			background-color: $primary;
			transition: all .3s;
		}
	}

	i{
		color: $white;
		@include ms-respond(font-size, 1.1);
	}

	.facebook{
		background-color: $facebook;
	}

	.twitter{
		background-color: $twitter;
	}

	.googleplus{
		background-color: $googleplus;
	}

	.email{
		background-color: $email;
	}
}