form{
	max-width: 800px;
	margin: auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.half{
		width: 50%;
		@media (max-width: $md) {
			width: 100%;
		}

		&.left{
			padding-right: 2.5%;
			@media (max-width: $md) {
				padding-right: 0;
			}

			p{
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
		&.right{
			padding-left: 2.5%;
			@media (max-width: $md) {
				padding-left: 0;
			}
		}
	}

	.full{
		width: 100%;

		input{
			&[type="submit"]{
				margin: auto;
			}
		}

		.wpcf7-recaptcha{
			&>div{
				margin: auto;
				display: block;
			}
		}

		p{
			max-width: unset;
			width: 100%;
			text-align: center;
		}
	}

	input{
		height: 50px;
		border: 2px solid $font-color;
		width: 100%;
		color: $font-color;
		font-family: inherit;
		padding: 10px;
		outline-color: $primary;

		&[type="submit"]{
			transition: all .3s;
			height: 60px;
			cursor: pointer;
			width: auto;
			min-width: 150px;
			&:hover{
				background-color: $primary;
				color: $white;
				border-color: $primary;
				transition: all .3s;
			}
		}
	}

	textarea{
		height: 270px;
		border: 2px solid $font-color;
		resize: none;
		width: 100%;
		color: $font-color;
		font-family: inherit;
		padding: 10px;
		outline-color: $primary;
	}

	label{
		margin-bottom: 20px;
		display: inline-block;
		width: 100%;
	}

	.g-recaptcha{
		margin-bottom: 30px;
	}

}