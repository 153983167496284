footer{
	background-color: $black;
	color: white;

	.footer-legal{
		text-align: center;
		@include ms-respond(font-size, .3);
		height: 40px;
		line-height: 40px;
		margin-top: 50px;
	}

	.footer-menu{
		ul{
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0;

			@media (max-width: $md) {
				display: block;
				margin-bottom: 50px;
			}

			li{
				list-style: none;
				text-align: center;

				a{
					color: $white;
					height: 50px;
					line-height: 50px;
					text-decoration: none;
					@include ms-respond(font-size, .6);
					display: inline-block;
					padding: 0 10px;
					text-transform: uppercase;
					transition: all .3s;
					letter-spacing: 2px;

					&:hover{
						color: $primary;
						transition: all .3s;
					}
				}
			}
		}
	}

	.social-menu{
		padding: 50px 0;
		

		ul{
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0;
		}
		a{
			color: $white;
			display: inline-block;
			@include ms-respond(font-size, 1.5);
			transition: all .3s;


			&:hover{
				color: $primary;
				transition: all .3s;
			}
		}
	}

	.current_page_item,
	.current-menu-parent{
		&>a{
			position: relative;
			&:after{
				position: absolute;
				bottom: 25%;
				left: 0;
				right: 0;
				background-color: $primary;
				content: '';
				display: block;
				height: 2px;
			}
		}
	}
}