iframe{
	display: block;
	//margin: auto;
	max-width: 100%;
	border: none;
	overflow: hidden;
	border: 0px;
}

.iframe{
	max-width: none;
	width: 100%;
}